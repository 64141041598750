<template>
  <v-navigation-drawer
    app
    class="lnb"
    width="240px"
    v-model="drawer"
    :clipped="$vuetify.breakpoint.mdAndUp"
  >
    <div class="logo">
      <a @click="setHome">
        <img src="@/assets/img/CRISPHeader.png" alt="logo" style="margin: 25px; width: 160px; height: 55px;" />
        <!--h1 style="font-size: 2.4rem; line-height: 2.2rem; padding-top: 1rem;color: #ffffff;">C R I S P</h1-->
        <!--small style="color: #EEEEEE; font-size: 10px;">Project  Visualization  System</small-->
      </a>
    </div>
    <div v-if="navItemAvailable" class="breadcrumb">{{ getMenuName() }}</div>

    <!-- Navigation Menu for System Administrator -->
    <!-- From the primitives/_navMenuSysAdmin.js -->
    <v-list v-if="navSysAdminAvailable" class="lnb_list">
      <v-list-tile
        v-for="item in navMenuSysAdmin"
        :key="item.title"
        :class="{ selected: selected(item.url, $route.path) }"
      >
        <i :class="[item.iconClass]"></i>
        <v-list-tile-content>
          <v-list-tile-title @click="route(item.url)">{{ item.title }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>

    <!-- Navigation Menu SubItem for Service -->
    <v-list v-else-if="navSubItemsAvailable" class="lnb_list">
      <v-list-tile
        v-for="item in navSubItemItems"
        :key="item.index"
        :class="{ selected: selectedSubItem(item, $route.path) }"
      >
        <i :class="['jcon_' + getClassName(item.subIconName)]"></i>
        <v-list-tile-content>
          <v-list-tile-title @click="route(item.url, item.subItemStatus)">{{ newItems(item,'subItemName') }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>

    <!-- Navigation Menu SubItem for Service Admin -->
    <!-- <v-list v-else-if="navSvcAdminAvailable" class="lnb_list">
      <v-list-tile
        v-for="item in navMenuSvcAdmin"
        :key="item.title"
        :class="{ selected: selected(item.url, $route.path) }"
      >
        <i :class="[item.iconClass]"></i>
        <v-list-tile-content>
          <v-list-tile-title @click="route(item.url)">{{ item.title }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list> -->

    <!-- Navigation Menu Extended by the Router Path -->
    <j-lnb-router-linked-menu 
      :style="navSysAdminAvailable || navSubItemsAvailable ? 'margin-top: -3rem;' : ''"
    ></j-lnb-router-linked-menu>

    <!-- Construction 3D Viewer Pipe Status Modules -->
    <j-lnb-o-m-s-modules v-if="threedViewerLnbModuleAvailable"></j-lnb-o-m-s-modules>

    <div v-if="!navSysAdminAvailable && !navSvcAdminMenu && (filterChainAvailable || filterOptionAvailable) && !threedViewerLnbModuleAvailable">
      <!-- Filter (Chain) Options -->
      <div v-if="filterChainAvailable" class="lnb_filter_chains">
        <j-lnb-filtering-chain v-for="chain in filterChains" :chain="chain" :key="chain.index" />
      </div>

      <!-- General Filter Options -->
      <j-lnb-filtering-options v-if="filterOptionAvailable" :value="filterOptions" />
    </div>

    <div v-if="!navSysAdminAvailable && filterCompAvailable" style="margin-left: .5rem;">
      <component
        mode-service
        :id="filterComponentId"
        :is="filterComponentName"
        :Canvas="filterComponentAttrs.canvas"
        :DataItems="filterCompOptions"
        :EnvValues="envValues"
        :FilterValues="filteredValues"
        @env-values="onEnvValues"
        @request-action="onFilterCompRequestAction"
      />
    </div>

    <!-- <j-lnb-report-category v-if="boardReportAvailable" /> -->
    <j-lnb-progress-block v-if="progressPhotoAvailable" />
    <!-- <j-lnb-equipment-block v-if="progressEquipmentAvailable" /> -->
    <j-lnb-pipe-viewr v-if="pipeviewrsAvailable" />

    <div v-if="exportAvailable" style="display: flex; justify-content: center;">
      <j-button class="type01 sky" @click="createExport('pdf', exportInfo, createAction, getItems, getIdx)">EXPORT PDF</j-button>
    </div>

    <div v-if="export3dAvailable" style="display: flex; justify-content: center;">
      <j-button class="type01 sky" @click="createExport3d">EXPORT PDF 3D</j-button>
    </div>

  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"

import __C from '@/primitives/_constant_'
import { SystemService, DBSupportService } from "@/services"

import NavMenuSysAdmin from "@/primitives/_navMenuSysAdmin"
import NavMenuSvcAdmin from "@/primitives/_navMenuSvcAdmin"
import Download from '@/mixins/download.mixin'
import Loading from '@/mixins/loading.mixin'
import JLnbRouterLinkedMenu from '@/components/JLnbRouterLinkedMenu'
import JLnbFilteringChain from "@/components/JLnbFilteringChain"
import JLnbFilteringOptions from "@/components/JLnbFilteringOptions"
import JSvgCustoms from '@/components/JLibSvgCustomComponents'
import JLnbReportCategory from '@/components/JLnbReportCategory'
import JLnbProgressBlock from  '@/components/JLnbProgressBlock'
import JLnbEquipmentBlock from  '@/components/JLnbEquipmentBlock'
import JLnbPipeViewr from  '@/components/JLnbPipeViewr'
import JLnbOMSModules from '@/components/JLnbOMSModules'

export default {
  name: 'j-lnb',
  mixins: [
    Download,
    Loading
  ],
  components: {
    JLnbRouterLinkedMenu,
    JLnbFilteringChain,
    JLnbFilteringOptions,
    JLnbReportCategory,
    JLnbProgressBlock,
    JLnbEquipmentBlock,
    JLnbPipeViewr,
    JLnbOMSModules,
    ...JSvgCustoms.Component,
  },
  props: {
    subItemEnabled: Boolean,
  },
  data: () => ({
    systemService: null,
    queryLibService: null,
    selectedItems: [],
    filterComponentId: '',
    navMenuSysAdmin: [],
    navMenuSvcAdmin: [],
    navItem: null,
    navSubItemItems: [],
    drawer: true,
    cmsCatCode: null
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapState(__C.STORE_NAMESPACE.APPLICATION, ['navState']),
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, [
      'childId', 
      'lnbRequestedAction',
      'prePropagated',
      'isExportInfo3d',
      'exportInfo3d'
    ]),
    ...mapState(__C.STORE_NAMESPACE.CONFIG, ['layout', 'language']),
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, [
      'exportInfo',
      'filterChains', 
      'filterOptions',
      'filteredValues',
      'filterCompAvailable',
      'getItems'
    ]),
    ...mapState(__C.STORE_NAMESPACE.DASHBOARD, [
      'dimention', 
      'envValues'
    ]),
    ...mapGetters(__C.STORE_NAMESPACE.DASHBOARD, [
      'filterComponentAttrs',
      'filterComponentName',
      'filterCompOptions'
    ]),

    __C_() { return __C },

    exportAvailable() {
      let notUsedPdf = ['/service/construction/3d_viewer/overall_module_status']
      let exportPdf_ = 'N'
      
      if(this.navItem) exportPdf_ = this.navItem.exportPdf? this.navItem.exportPdf : 'N'
      
      return !notUsedPdf.includes(this.$route.path) && 
            this.childId == __C.STORE_NAMESPACE_KEY.DASHBOARD && 
            this.$route.path.includes('/service') &&
            !this.$route.name.includes('CSU Sequence') &&
            exportPdf_ == 'Y'
    },
    
    export3dAvailable() {
      let exportUrl = ['/service/construction/3d_viewer/overall_module_status']
      return exportUrl.includes(this.$route.path) && this.childId == __C.STORE_NAMESPACE_KEY.DASHBOARD && this.$route.path.includes('/service')
    },

    cmsLevelAvailable() {
      // if(!this.$route.path.includes('/service/planning/master_schedule/construction_master_schedule')) return false

      // if(this.$route.path.includes('hull_rebaseline')) this.cmsNavType = 'hull_rebase'
      // else if (this.$route.path.includes('hull')) this.cmsNavType = 'hull'
      // else if (this.$route.path.includes('topsides_rebaseline')) this.cmsNavType = 'topsides_rebase'
      // else this.cmsNavType = 'topsides'

      // if (!this.cmsCatCode == 'P6') return false
      // return true
      return this.cmsCatCode
    },
    
    threedViewerLnbModuleAvailable() {
      if(!this.$route.path.includes('/service/construction/3d_viewer/overall_module_status')) return false
      return true
    },

    filterChainAvailable() { 
      return this.filterChains.length !== 0 
    },
    filterOptionAvailable() { return this.filterOptions.length !== 0 },

    navSysAdminAvailable() { return !this.subItemEnabled && this.navMenuSysAdmin.length > 0 },
    navSvcAdminMenu() { return this.$route.path == '/service/account_management/account_management/user_service/access_history' ||
                                this.$route.path == '/service/account_management/account_management/user_service/account_management' },
    navItemAvailable() { return this.navItem },
    navSubItemsAvailable() { return this.subItemEnabled && this.navSubItemItems.length > 0 },
    navSvcAdminAvailable() { 
      return (
        ['SYS_ADMIN', 'SVC_ADMIN'].includes(this.account.userPermit) &&
        this.$route.path.includes('/admin') 
      )
    },
    accessHistAvailable() { return this.navSvcAdminAvailable && this.$route.path == '/admin/accesshist'},
    progressPhotoAvailable() { return this.$route.path == '/service/project/progress_photo_video/aerial_photo' || this.$route.path == '/service/project/progress_photo_video/construction_photo'},
    progressEquipmentAvailable() { return this.$route.path == '/service/project/progress_photo_video/construction_photo'},
    pipeviewrsAvailable() { return this.$route.path == '/service/construction/3d_viewer/pipe_status' },
    boardReportAvailable() { 
      return (
        !this.filterChainAvailable &&
        !this.filterOptionAvailable &&
        !this.navSysAdminAvailable &&
        !this.navSubItemsAvailable && 
        this.childId == __C.STORE_NAMESPACE_KEY.BOARD_REPORT 
      )
    },
  },
  watch: {
    '$route.path'(val) {
      this.filterComponentId = `nav_comp_${String(new Date().getTime())}`
      this.getCatCode()
    },
    navState: { 
      handler(val) { 
        this.getNavItem(val)
        if(this.subItemEnabled) this.getSubItems(val) 
      }, 
      deep: true 
    },
    isExportInfo3d(val) {
      if (!val) return
      this.createExport('pdf', this.exportInfo, this.createAction, this.getItems, this.getIdx)
      
      this.ExportInfo3dSet('')
      this.IsExportInfo3dSet(false)
    }
  },
  created() {
    this.systemService = new SystemService()
    this.queryLibService = new DBSupportService()
    this.navMenuSysAdmin = NavMenuSysAdmin.items
    this.navMenuSvcAdmin = NavMenuSvcAdmin.items
  },
  mounted() {
    this.getSubItems(this.navState)
    this.getNavItem(this.navState)
    this.getCatCode()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'initService',
    ]),
    ...mapActions(__C.STORE_NAMESPACE.APP_SERVICE, [
      'getIdx',
      'createAction',
      'sendRequest', 
      'updateFilteredValues',
      'IsPdfExort3dSet',
      'IsExportInfo3dSet',
      'ExportInfo3dSet'
    ]),
    ...mapActions(__C.STORE_NAMESPACE.DASHBOARD, [
      'updateEnvValues'
    ]),

    createExport3d(){
      // console.log(this.account.token)
      // console.log('createExport3d')
      this.IsPdfExort3dSet(true)
    },
    getMenuName() {
      return this.language == 'cn' && this.navItem.cnName ? this.navItem.cnName : this.navItem.itemName
    },
    newItems(name,types) {
      return name = this.language == 'cn' && name.cnName ? name.cnName : name[types]
    },

    onEnvValues(values) {
      this.updateEnvValues(values)
    },
    onFilterCompRequestAction(request) {
      this.loading = true
      this.updateFilteredValues({ ...request.filters }).then(() => {
        this.sendRequest('filtered').then(() => { this.loading = false })
      })
    },
    getCatCode(){
      if(!this.navState || !this.navState.id) return
      
      this.queryLibService.getSqlQueryResult({
        idx: 0, 
        name: 'getCatCode',
        filters: ` [SUB_ITEM] = '${this.navState.id}'`
      }).then(res => {
        if (!res) {
          this.cmsCatCode = null  
          return
        }
        this.cmsCatCode = res[0].CAT_CODE == 'P6' ? 'P6' : null
      })

    },
    getClassName(name) {
      if (!name) return ''
      return name.toLowerCase().split('.')[0]
    },
    getSubItems(code) {
      if (!code || !code.code) {
        this.navSubItemItems = []
        return
      }

      this.systemService.getNavSubItem(
        res => {
          if (!res) res = [];
          this.navSubItemItems = res;
        },
        code.parentCode,
        code.code
      );
    },
    getNavItem(code) {
      this.navItem = null

      if (!code || !code.code) {
        this.navItem = null
        return
      }

      this.systemService.getNavItem(
        res => {
          if (!res) this.navItem = null
          else this.navItem = res.find(d => d.itemCode == code.code)
        },
        code.parentCode,
        code.groupCode
      );
    },
    route(path, status = null) {
      if(status && status == 'PLANNED') return
      if(this.$route.path == path) return

      let loadables = [
        'ServiceIntendedDatagrid'
      ]
      let direction = this.$route.meta.direction ? this.$route.meta.direction : null
      if(direction && loadables.some((loadable) => direction.includes(loadable))) this.loading = true
      
      // this.initService()
      this.$router.push(path)
    },
    selected(path, matching, exact = false) {
      if(!path || !matching) return false
      if(exact) {
        if (path == matching) return true
        return false
      } else {
        if (matching.search(path) > -1) return true
        return false
      }
    },
    selectedSubItem(item, path) {
      if(item.url != path) return false
      return true
    },
    setHome() {
      if (this.$route.path == '/service/mechanical_completion/dashboard/dashboard_by_area') return
      if(this.$route.path == this.$__home) return
      this.$router.push(this.$__home)
    },
  }
};
</script>

