<template>
  <div>
    <v-navigation-drawer v-model="snbDrawer" fixed app right class="snb" width="70px" :class="classStatus">
      <v-avatar 
        class="snb_user_avatar"
        :title="account.userName"
        @click="onMouseOver">
        <v-icon></v-icon>
      </v-avatar>
      <!-- <div class="message_status">
        <a @click="messengerOpen=!messengerOpen" title="notice">
          <i class="jcon_message"></i>
        </a>
        <div v-if="unreadMessages>0" class="unread_notices">{{ unreadMessages }}</div>
      </div> -->
      <a @click="notification()" title="notification">
        <v-icon :style="noticeStyle">mdi-bell-ring</v-icon>
      </a>
      <a @click="openComment()" title="comments">
        <v-icon :style="commentStyle">mdi-message-text-outline</v-icon>
      </a>
      <div v-if="comment_count > 0" :style="countStyle">{{ comment_count }}</div>            
      <a @click="toggleFullscreen()" title="full screen">
        <v-icon :style="iconStyle">mdi-fullscreen</v-icon>
      </a>
      <!-- <a @click="signout();closeAllFloats();" title="signout">
        <i class="jcon_logout"></i>
      </a> -->
      <a @click="closeAllFloats();pageOut();" title="signout">
        <i class="jcon_logout"></i>
      </a>
    </v-navigation-drawer>


  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import __M from 'moment'
import Comment from '@/mixins/comment.mixin'
import { mapState, mapMutations, mapActions } from 'vuex'
import { SystemCodeService, CommunicationService } from '@/services'
export default {
  mixins: [
    Comment
  ],
  data: () => ({
    communicationService: null,
    comment_count: 0,
    snbDrawer: true,
    systemCodeService: null,
    currentUrlData: [],
    Comment_Data: [],
    Common_comments: [],
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, [
      'commentOpened', 
      'filemanagerOpened', 
      'messengerOpened', 
      'modalOpened',
      'userInfoOpened',
      'urlOptions',
      'commentProps',
      'updatecomment'
    ]),
    ...mapState(__C.STORE_NAMESPACE.NOTICE, ['unreads']),

    iconStyle() {
      return {
        position: 'relative',
        left: '-1px',
        top: '-20px',
        fontSize: '4.2rem'
      }
    },
    noticeStyle() {
      return {
        position: 'relative',
        left: '-1px',
        fontSize: '3.2rem'
      }
    },
    commentStyle() {
      return {
        position: 'relative',
        left: '-1px',
        top: '-10px',
        fontSize: '3.2rem'
      }
    },
    countStyle() {
      return {
        position: 'absolute',
        top: '14.2rem',
        left: '3.5rem',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        width: '1.7rem',
        height: '1.7rem',
        color: '#fff',
        'font-size': '.9rem',
        'border-radius': '50%',
        'background-color': '#7CB342',
        'pointer-events': 'none',
        opacity: '.9'
      }
    },
    classStatus() { return this.commentOpened || this.filemanagerOpened || this.messengerOpened || this.modalOpened ? 'modalOpened' : 'modalClosed' },
    messengerOpen: {
      get() { return this.messengerOpened },
      set(val) { this.setMessengerOpened(val) }
    },
    unreadMessages() { 
      return Object.keys(this.unreads).length > 0 ? this.unreads.non + this.unreads.group + this.unreads.one : 0 
    },
  },
  watch: {
    urlOptions() {
      this.getUrlDataSet()
    },
    updatecomment(v) {
      this.getUrlDataSet()
    }
  },
  created() {
    this.systemCodeService = new SystemCodeService()
    this.communicationService = new CommunicationService()
    // this.getUrlDataSet()
  },
  mounted() {
    this.getUrlDataSet()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'closeAllFloats', 
      'setMessengerOpened', 
      'setUserInfoOpened',
      'setNoticeOpened', 
      'setNoticeOtipons', 
      'setCommentsOpened',
      'setCommentTitle'
    ]),
    ...mapActions(__C.STORE_NAMESPACE.ACCOUNT, ['signout']),
    notification() {
      this.setNoticeOpened(true)
      this.setNoticeOtipons(true)
    },
    comments() {
      this.setCommentsOpened(true)
    },
    onMouseOver() {
      this.setUserInfoOpened(true)
    },
    openComment() {
      if (this.$route.path.includes('/sysenv')) return
      
      let title = this.currentUrlData[0].subItemName == "" 
      ? this.currentUrlData[0].itemName
      : this.currentUrlData[0].itemName + ` (${this.currentUrlData[0].subItemName})` 
      
      this.setCommentTitle(title)
      this.setCommentProps({ refCode: `GlobalComment_${this.currentUrlData[0].idx}` })
      this.onCommentOpen()
    },
    getCount() {
      return this.comment_count > 0
    },    
    getUrlDataSet() {
      setTimeout(() => {
        this.systemCodeService.getUrlData(
          res => {
            if (res && res.length > 0) { 
              this.currentUrlData = res.filter(d => d.url == this.urlOptions)
            }
          }
        )
      },200)
      setTimeout(() => {
        this.systemCodeService.getComment(
          res => {
            this.comment_count = 0
            if (res && res.length > 0) {
              
              res.forEach(d => {
                if (this.currentUrlData.length > 0 && d.refCode.includes(`_${this.currentUrlData[0].idx}`)) {
                  // Count only the last 30 days
                  let diff = __M(new Date()).diff(d.createdDate, 'days')
                  if (diff < 30) this.comment_count++
                  else return
                }
              })
            }
            this.getCount()
          }
        )
      },400)
    },
    toggleFullscreen(el) {
      el = el || document.documentElement
      if (!document.fullscreenElement && !document.mozFullScreenElement &&
        !document.webkitFullscreenElement && !document.msFullscreenElement) {
        if (el.requestFullscreen) {
          el.requestFullscreen()
        } else if (el.msRequestFullscreen) {
          el.msRequestFullscreen()
        } else if (el.mozRequestFullScreen) {
          el.mozRequestFullScreen()
        } else if (el.webkitRequestFullscreen) {
          el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        }
      }
    },

    pageOut() {
      this.$router.push({ path: '/pageout' })
    }
  }
};
</script>
